<template>
  <div :class="[ 'article-item', { 'article-item--big': isBig }]">
    <esmp-link :to="`/articles/${id}`" class="article-item__wrap">
      <div class="article-item__img" :style="`background-image: url(${img})`" />
      <div class="article-item__title">
        {{ title }}
      </div>
      <esmp-link
        v-if="isBig"
        class="article-item__link"
        :to="`/articles/${id}`"
      >
        Посмотреть&nbsp;&rarr;
      </esmp-link>
    </esmp-link>
  </div>
</template>

<script>
export default {
  name: 'ArticleListItem',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    img: {
      type: String,
      default: '',
    },
    isBig: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.article-item {
  $base-class: &;
  width: 100%;

  &__wrap {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__img {
    width: 80px;
    height: 80px;
    background-position: center;
    background-size: cover;
    border-radius: 12px;
    margin-right: 24px;
    flex-shrink: 0;
  }

  &__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    flex-grow: 1;
    color: $color-black;
    transition: color $base-animation-time;
  }

  &__link {
    position: absolute;
    bottom: 32px;
    left: 32px;
    color: $color-white;
    transition: color $base-animation-time;
  }

  &#{$base-class}--big {
    #{$base-class}__img {
      width: 100%;
      height: 217px;
      margin-right: 0;
    }

    #{$base-class}__title {
      position: absolute;
      top: 32px;
      left: 32px;
      right: 32px;
      font-size: 16px;
      color: $color-white;
    }

  }

  &:hover {
    #{$base-class}__title,
    #{$base-class}__link {
      color: $color-client-portal-logo;
    }
  }
}
</style>
